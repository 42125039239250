<template>
	<div id="cpm_seedList" class="el-content">
		<a-tabs>
		    <a-tab-pane key="seed" tab="种子列表">
				<a-space style="margin-bottom: 12px;">
					<a-input-search 
						placeholder="种子名称" 
						v-model:value="search.key" 
						@search="getSeedList(1,info.limit)" 
						style="width: 400px;">
					    <a-button><a-icon type="search" />搜索</a-button>
					</a-input-search>
					<router-link :to="{path:isShop == 0 ? '/land/seedEdit':'/many_store/land/seedEdit'}">
						<a-button type="primary" v-has="{action:'/land/seedEdit',plat:isShop}"><i class="ri-add-line ri-lg ri-top"></i>新增种子</a-button>
					</router-link>
					<a-button type="primary" v-has="{action:'wxapp_land_set_seed_hot',plat:isShop}" @click="setHot(pks)"><i class="ri-star-line ri-lg ri-top"></i>设置热门</a-button>
					<a-button type="danger" v-has="{action:'wxapp_land_seed_del',plat:isShop}" @click="deleteSeed(pks)"><i class="ri-delete-bin-5-line ri-lg ri-top"></i>批量删除</a-button>
				</a-space>
				<a-table class="tb-overflow" row-key="id" :pagination="false" :data-source="info.list" :columns='[
					{dataIndex:"id",title:"ID",width:100},
					{dataIndex:"send_name",title:"种子名称",slots: { customRender: "send_name" },width:200},
					{dataIndex:"cate.name",title:"种子分类",width:120},
					{dataIndex:"price",title:"价格(1/㎡)",slots:{customRender:"price"},width:120},
					{dataIndex:"output",title:"产量(1/㎡)",slots:{customRender:"output"},width:160},
					{dataIndex:"cycle",title:"周期",slots:{customRender:"cycle"},width:180},
					{dataIndex:"plant_num",title:"累计种植面积",slots:{customRender:"plant_num"},width:150},
					{dataIndex:"is_hot",title:"是否热门",slots:{customRender:"is_hot"},width:100},
					{dataIndex:"update_time",title:"最后更新时间",width:200},
					{dataIndex:"action",title:"操作",slots:{customRender:"action"},width:200},
				 ]' :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }" :scroll="{x:300}">
				    <template #send_name="{record}">
						<div class="kd-seedlist">
							<a-image class="kd-seedlist-cover" :src="record.cover"/>
							<span>{{record.send_name}}</span>
						</div>
				    </template>
					<template #price="{record}">
						{{record.price}}元/㎡
					</template>
					<template #output="{record}">
						{{record.output}}kg/㎡
					</template>
					<template #cycle="{record}">
						<div><span class="table-tips">生长：</span>{{record.cycle}}天</div>
						<div><span class="table-tips">采摘：</span>{{record.pickingcycle}}天</div>
					</template>
					<template #plant_num="{record}">
						{{record.plant_num}}㎡
					</template>
					<template #is_hot="{record}">
						<a-tag :color=" record.is_hot == 1 ? '#f50' :'#999'">{{ record.is_hot == 1 ? '热门':'否'}}</a-tag>
					</template>
					<template #action="{record}">
						<a-space>
							<router-link :to="{path:isShop == 0 ? '/land/seedEdit':'/many_store/land/seedEdit',query:{id:record.id}}">
								<kd-button 
									type="primary" 
									icon="ri-edit-2-line" 
									title="编辑" 
									v-has="{action:'/land/seedEdit',plat:isShop}">
								</kd-button>
							</router-link>
							<kd-button 
								type="danger" 
								icon="ri-delete-bin-5-line" 
								title="删除" 
								@click="deleteSeed([record.id])" 
								v-has="{action:'wxapp_land_seed_del',plat:isShop}">
							</kd-button>
						</a-space>
					</template>
				</a-table>
				<div class="pager">
					<a-pagination
					    show-size-changer
					    :default-current="info.page"
					    :total="info.count"
					    @showSizeChange="(p,e)=>{getSeedList(info.page,e)}"
						@change="(e)=>{getSeedList(e,info.limit)}"
					/>
				</div>
			</a-tab-pane>
		    <a-tab-pane key="type" tab="种子分类">
				<a-button type="primary" @click="addType(0)" v-has="{action:'wxapp_seed_type_add',plat:isShop}"><i class="ri-add-line ri-lg ri-top"></i>新增分类</a-button>
				<a-table row-key="id" :pagination="false" :data-source="type" :columns='[
					{dataIndex:"id",title:"ID"},
					{dataIndex:"name",title:"分类名称"},
					{dataIndex:"rank",title:"排序"},
					{dataIndex:"action",title:"操作",slots:{customRender:"action"}},
				 ]' style="margin-top: 12px;">
					<template #action="{record}">
						<a-space>
							<kd-button 
								type="primary" 
								icon="ri-edit-2-line" 
								title="编辑" 
								@click="addType(record)" 
								v-has="{action:'wxapp_seed_type_add',plat:isShop}">
							</kd-button>
							<kd-button 
								type="danger" 
								icon="ri-delete-bin-5-line" 
								title="删除" 
								@click="deleteSeedType(record.id)" 
								v-has="{action:'wxapp_seed_type_add',plat:isShop}">
							</kd-button>
						</a-space>
					</template>
				</a-table>
		    </a-tab-pane>
		</a-tabs>
		<a-modal title="添加分类" :visible="showEdit" @ok="saveSeedType" @cancel="showEdit = false" width="600px">
			<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
			    <a-form-item label="分类名称">
					<a-input v-model:value="form.name" placeholder="分类名称"/>
			    </a-form-item>
				<a-form-item label="分类封面">
					<kd-img-select :src="form.image" @change="(url)=>{ form.image = url }"></kd-img-select>
				</a-form-item>
				<a-form-item label="分类排序">
					<a-input v-model:value="form.rank" placeholder="分类名称"/>
				</a-form-item>
			</a-form>	
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
import common from '@/api/common.js'
export default{
	name:"page-seed-list",
	props:{
		isShop:{
			type:Number
		}
	},

	setup(){
		const _d = reactive({
			active:'seed',
			search:{key:""},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
			pks:[],
			type:[],
			showEdit:false,
			form:null
		})
		getSeedList(1,_d.info.limit)
		getTypeList(1,999)
		function getSeedList(page,limit){
			landModel.getSeed(page,limit,_d.search,res=>{
				_d.info = { limit, ...res}
			})
		}
		function getTypeList(page,limit){
			landModel.getSeedType(res=>{
				_d.type = res
			})
		}
		const deleteSeed = (id)=> landModel.deleteSeed(id,()=>{
			getSeedList(_d.info.page,_d.info.limit)
		})

		const setHot = ( id ) => landModel.setSeetHot(id,()=>{
			getSeedList(_d.info.page,_d.info.limit)
		})

		function addType(row){
			_d.form = {
				id:row ? row.id :0,
				name:row ? row.name:'',
				rank:row ? row.rank:99,
				image:row ? row.image:""
			}
			_d.showEdit = true
		}
		const saveSeedType = ()=> landModel.addOrEditSeedType(_d.form,()=>{
			getTypeList(1,999)
			_d.showEdit = false
		})

		const deleteSeedType = (id)=> common.deleteDataList(id,58,'确认删除该种子分类吗?').then(()=>{
			getTypeList(1,999)
		})

		return{
			...toRefs(_d),
			getSeedList,
			deleteSeed,
			setHot,
			addType,
			deleteSeedType,
			saveSeedType
		}
	},
}
</script>

<style lang="scss">
	.kd-seedlist{
		width: 100%;
		display: flex;
		
		&-cover{
			width: 30px;
			height: 30px;
			border-radius: 5px;
			margin-right: 10px;
		}
	}
</style>
